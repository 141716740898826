import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";


import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import CssBaseline from "@mui/material/CssBaseline";
import theme from './theme';

import { createBrowserHistory } from 'history';

const App = lazy(() => import('./App'));
const ContactPage = lazy(() => import('./routes/contact'));
const AboutPage = lazy(() => import('./routes/AboutUs'));
const PrivacyPolicyTOSPage = lazy(() => import('./routes/privacy'));


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBeaqOFsvPKviPqCCSYZTafgaKA8z-Uis8",
    authDomain: "usergen-e7c84.firebaseapp.com",
    projectId: "usergen-e7c84",
    storageBucket: "usergen-e7c84.appspot.com",
    messagingSenderId: "733748700573",
    appId: "1:733748700573:web:56cbd4cb102d8079063b74"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/about",
        element: <AboutPage />,
    },
    {
        path: "/contact",
        element: <ContactPage />,
    },
    {
        path: "/privacy",
        element: <PrivacyPolicyTOSPage />,
    },
]);

const basename = document.querySelector('base')?.getAttribute('href') ?? '/'

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
            console.log('SW registered: ', registration);
        }).catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<div>Loading...</div>}>
                <RouterProvider router={router} />
            </Suspense>
        </ThemeProvider>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
